<template>
    <a-row type='flex' :gutter="12">
      <a-card v-auth="'messageCenter:systemMsg'" :hoverable='true' class="card-item" @click="toPage('OperationManageSystem')">
        <div class="system-img" alt=""></div>
        <div class="title">系统消息管理</div>
      </a-card>

      <a-card v-auth="'messageCenter:activityMsg'" :hoverable='true' class="card-item" @click="toPage('OperationManageActivity')">
        <div class="activity-img" alt=""></div>
        <div class="title">活动通知管理</div>
      </a-card>
    </a-row>
</template>

<script>
export default {
  name: 'OperationMain',
  data () {
    return {
    }
  },
  methods: {
    toPage (name) {
      this.$router.push({name})
    }
  }
}
</script>

<style lang="less" scoped>
.card-item {
  width: 200px;
  height: 200px;
  margin: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .system-img {
    width: 100px;
    height: 100px;
    background: url('./images/system.png');
  }
  .activity-img {
    width: 100px;
    height: 100px;
    background: url('./images/activity.png');
  }
  &:hover {
    .title, i {
      color: #52bbe4;
    }
    .system-img {
      background: url('./images/system_active.png');
    }
    .activity-img {
      background: url('./images/activity_active.png');
    }
  }
  .title {
    text-align: center;
    margin-top: 12px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
